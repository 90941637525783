import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { SaleService } from "../services/SaleService";
import { IInvoice } from "../common/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CommonService } from "../services/CommonService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from './logo.png';
const MySwal = withReactContent(Swal);

export const Main = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [folio, setFolio] = React.useState<string>("");
  const [total, setTotal] = React.useState<string>("");
  const [steps, setSteps] = React.useState<number>(0);
  const [invoice, setInvoice] = React.useState<IInvoice>();
  const formElement = useRef({} as HTMLFormElement);
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [rfc, setRfc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [regimentFiscal, setRegimenFiscal] = React.useState("");
  const [useCfdi, setUsoCfdi] = React.useState("");

  const [listUsoCfdi, setListUsoCfdi] = React.useState([]);
  const [listAvailableUse, setListAvailableUse] = React.useState([]);
  const [listRegimen, setListRegimen] = React.useState([]);
  const [controlTick, setControlTick] = React.useState(0);
  const baseApp = process.env.REACT_APP_API_URL;
  useEffect(() => {
    CommonService.getCatalog("regimenFiscal").then((response) => {
      setListRegimen(response);
    });
    CommonService.getCatalog("usoCFDI").then((response) => {
      setListUsoCfdi(response);
    });
    if(searchParams.get('folio') && searchParams.get('total')){
      setFolio(searchParams.get('folio') ?? '')
      setTotal(searchParams.get('total') ?? '')
      setControlTick(controlTick+1)
    
    }

  }, []);
  useEffect(() => {
    if(folio!= '' && total != ''){
      checkFolio()
    }
  },[controlTick])
  const getSaleInfo = (id: number) => {
    return SaleService.getSale(id);
  };

  const handleRFC = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRfc(event.target.value);
    if (event.target.value.length >= 12) {
      SaleService.getCustomerByRFC(event.target.value).then((res) => {
        if (res.object?.id) {
          setName(res.object.nombre);
          setPhone(res.object.telefono ?? '');
          setAddress(res.object.direccion);
          setRfc(res.object.rfc);
          setEmail(res.object.email);
          setPostalCode(res.object.codigoPostal);
          setRegimenFiscal(res.object.regimenFiscal);
          const availableUso = listUsoCfdi.filter(
            (uso: any) =>
              uso.regimen_permitidos.search(res.object?.regimenFiscal) !== -1
          );
          setListAvailableUse(availableUso);
          setUsoCfdi(res.object.usoCFDI);
        }
      });
    }
  };
  const handleSubmitCreateInvoice = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    console.log({
      phone,
      name,
      rfc,
      email,
      address,
      postalCode,
      regimentFiscal,
      useCfdi,
    });
    if (regimentFiscal == "") {
      MySwal.fire({
        title: "El regimen fiscal es un campo requerido",
        icon: "error",
      });
      return;
    }
    if (useCfdi == "") {
      MySwal.fire({
        title: "El uso de CFDI es un campo requerido",
        icon: "error",
      });
      return;
    }

    MySwal.fire({
      title: <p>Generando Factura</p>,
      didOpen: () => {
        MySwal.showLoading();
        SaleService.generateInvoice(parseInt(folio), {
          phone,
          name,
          rfc,
          email,
          address,
          postalCode,
          regimenFiscal: regimentFiscal,
          useCfdi,
        }).then((res) => {
          console.log(res)
          if (res.success) {
            MySwal.fire({
              title: "Su factura ha sido creada con éxito, en breve recibirá su factura al correo suministrado",
              icon: "success",
            });
            setSteps(0);
            setPhone('')
            setName('')
            setRfc('')
            setEmail('')
            setAddress('')
            setPostalCode('')
            setRegimenFiscal('')
            setUsoCfdi('')
            setFolio('')
            setTotal('')
            return;
          }
          MySwal.hideLoading()
          // MySwal.hi
          MySwal.fire({
            title: res.message,
            icon: "error",
          });
        });
      },
    });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await checkFolio();
  };

  const checkFolio = async () => {
    const saleInformation = await getSaleInfo(parseInt(folio || "0"));
    console.log({ saleInformation, total });
    if (
      saleInformation.success &&
      saleInformation.object &&
      saleInformation.object?.total === total
    ) {
      const hasInvoice = !saleInformation.object.facturas.every(
        (factura) => factura.status !== "Timbrado"
      );
      console.log({ hasInvoice });
      if (hasInvoice) {
        console.log("Factura ya generada");
        const inv = saleInformation.object.facturas.find(
          (factura) => factura.status === "Timbrado"
        );
        setInvoice(inv);
        setSteps(1);
        return;
      }
      console.log("Orden lista para generar Factura");
      setSteps(2);
      return;
    }
    alert(
      "No se encontrón información de la venta, por favor verifica la información ingresada"
    );
  }

  return (
    <Container fluid="md">
      <FloatingWhatsApp phoneNumber="528994586171" avatar={`${process.env.PUBLIC_URL}/img/logo1.png`} accountName="LaBiferia" darkMode chatMessage="¡Hola!, ¿Necesitas ayuda para generar tu factura?, con gusto podemos ayudarte."/>
      {steps === 0 && (
        <>
          <Row>
            <Col>
              <h1 className="title text-center mt-3">
                Ingrese su código de facturación
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="6">
              <Form onSubmit={handleSubmit} ref={formElement} >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Folio de Ticket</Form.Label>
                  <Form.Control
                    value={folio}
                    onChange={(e) => setFolio(e.target.value)}
                    type="text"
                    placeholder="Folio de Ticket"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Total Cuenta</Form.Label>
                  <Form.Control
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    type="text"
                    placeholder="Total de Cuenta"
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Continuar
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}
      {steps === 1 && (
        <>
          <Row>
            <Col>
              <h1 className="title text-center mt-3">
                Este ticket ya ha sido previamente facturado.
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="6">
              <p className="text-center">Factura UUID: {invoice?.uuid}</p>
              <p className="text-center">
                <Button
                  variant="primary"
                  className="mt-3"
                  href={baseApp + "facturacion/pdffile/" + invoice?.uuid}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDownload} />
                  PDF
                </Button>
                <Button
                  variant="primary"
                  className="mt-3 ml-3"
                  style={{ marginLeft: "30px" }}
                  href={baseApp + "facturacion/xmlfile/" + invoice?.uuid}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDownload} />
                  XML
                </Button>
                <Button
                  variant="danger"
                  className="mt-3 ml-3"
                  style={{ marginLeft: "30px" }}
                  onClick={() => {
                    setSteps(0);
                    setInvoice(undefined);
                    setTotal('');
                    setFolio('');
                  }}
                >
                  Regresar
                </Button>
              </p>
            </Col>
          </Row>
        </>
      )}

      {steps === 2 && (
        <>
          <Row>
            <Col>
              <h1 className="title text-center mt-3">
                Capture la información para generar la factura
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="6">
              <Form onSubmit={handleSubmitCreateInvoice}>
              <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>RFC</Form.Label>
                  <Form.Control
                    value={rfc}
                    onChange={handleRFC}
                    type="text"
                    required
                    placeholder="Rfc"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Telefono</Form.Label>
                  <Form.Control
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    required
                    placeholder="Telefono"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Email</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    required
                    placeholder="Email"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Nombre</Form.Label>
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value.toUpperCase())}
                    type="text"
                    required
                    placeholder="Nombre"
                  />
                </Form.Group>
                
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Direccion</Form.Label>
                  <Form.Control
                    value={address}
                    onChange={(e) => setAddress(e.target.value.toUpperCase())}
                    type="text"
                    required
                    placeholder="Direccion"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Código Postal</Form.Label>
                  <Form.Control
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    type="text"
                    required
                    placeholder="Codigo postal"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Regimen Fiscal</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={regimentFiscal}
                    required
                    onChange={(e) => {
                      setRegimenFiscal(e.target.value);
                      const availableUso = listUsoCfdi.filter(
                        (uso: any) =>
                          uso.regimen_permitidos.search(e.target.value) !== -1
                      );
                      setListAvailableUse(availableUso);
                    }}
                  >
                    <option>-- Seleccione --</option>
                    {listRegimen.map((regiment: any) => {
                      return (
                        <option value={regiment.clave}>
                          {regiment.descripcion}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label value>Uso de CFDI</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={useCfdi}
                    required
                    onChange={(e) => setUsoCfdi(e.target.value)}
                  >
                    <option>-- Seleccione --</option>
                    {listAvailableUse.map((regiment: any) => {
                      return (
                        <option value={regiment.clave}>
                          {regiment.descripcion}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Generar Factura
                </Button>
                <Button
                  variant="primary"
                  type="reset"
                  style={{ marginLeft: "30px" }}
                  onAbort={() => {
                    setRfc('')
                    setPhone('')
                    setName('')
                    setEmail('')
                    setAddress('')
                    setPostalCode('')
                    setRegimenFiscal('')
                    setUsoCfdi('')
                  }}
                >
                  Limpiar
                </Button>
                <Button
                  variant="danger"
                  style={{ marginLeft: "30px" }}
                  onClick={() => {
                    setInvoice(undefined);
                    setSteps(0);
                    setTotal('');
                    setFolio('');
                  }}
                >
                  Cancelar
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

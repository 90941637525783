import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

export const Header = () => {
  return (
    <Navbar expand="lg" bg="dark" variant="dark"
    style={{
      background: 'rgba(33, 36, 40, 0.9)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    }}
    >
      <Container>
        <Navbar.Brand href="#home">
        <img
          className="img-fluid"
          src={`${process.env.PUBLIC_URL}/img/logo1.png`}
          style={{width: "70px"}}
          alt="logo"
        />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" style={{fontSize: "25px"}}>Facturación</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

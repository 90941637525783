import { ICustomer, IPayment, IResponse, ISale, TRequestSale } from "../common/interfaces";

const baseApi = process.env.REACT_APP_API_URL;
export const CommonService = {
  getCatalog: async (name: string, ) => {
    const response = await fetch(`${baseApi}catalog/${name}`);
   
    return await response.json();
  }

};

import { ICustomer, IPayment, IResponse, ISale, TRequestSale } from "../common/interfaces";

const baseApi = process.env.REACT_APP_API_URL;
export const SaleService = {
  createSalePos: async (params: TRequestSale): Promise<IResponse<ISale>> => {
    console.log(params);
    const response = await fetch(`${baseApi}sales`, {
      method: "POST",
      body: JSON.stringify(params),
    });
    return await response.json();
  },
  getSale: async (id: number): Promise<IResponse<ISale>> => {
    const response = await fetch(`${baseApi}sales/${id}/complete`);
    return await response.json();
  },
  generateInvoice: async (id: number, params: Record<string, any>): Promise<IResponse<ISale>> => {
    const response = await fetch(`${baseApi}sales/${id}/invoice`, {
      method: "POST",
      body: JSON.stringify(params)
    })
    return await response.json()
  
  },
  payOrder: async (
    id: number,
    params: Record<string, any>
  ): Promise<IResponse<IPayment>> => {
    const response = await fetch(`${baseApi}sales/${id}/pay`, {
      method: "POST",
      body: JSON.stringify(params),
    });
    return await response.json();
  },
  updateOrder: async (
    id: number,
    status: string
  ): Promise<IResponse<IPayment>> => {
    const response = await fetch(`${baseApi}sales/${id}/${status}`, {
      method: "POST",
    });
    return await response.json();
  },
  getCustomerByPhone: async (phone: string): Promise<IResponse<ICustomer>> => {
    const response = await fetch(`${baseApi}customer/${phone}`);
    return await response.json();
  },
  getCustomerByRFC: async (rfc: string): Promise<IResponse<ICustomer>> => {
    const response = await fetch(`${baseApi}customer/rfc/${rfc}`);
    return await response.json();
  },

};
